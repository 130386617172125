import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import { randomColor } from "https://framer.com/m/framer/utils.js@^0.9.0"
import { useState } from "react"

const useStore = createStore({
    background: "#0099FF",
    inputValue: "", // Estado del input del formulario
})

export function withRotate(Component) {
    return (props) => {
        return (
            <Component
                {...props}
                animate={{ rotate: 90 }}
                transition={{ duration: 2 }}
            />
        )
    }
}

export function withHover(Component) {
    return (props) => {
        return <Component {...props} whileHover={{ scale: 1.05 }} />
    }
}

export function withRandomColor(Component) {
    return (props) => {
        const [store, setStore] = useStore()
        const { inputValue } = store // Obtener el estado del input del store

        const handleChange = (event) => {
            setStore({ inputValue: event.target.value }) // Actualizar el estado del input en el store
        }

        const handleReset = () => {
            setStore({ inputValue: "" }) // Resetear el estado del input en el store
        }

        return (
            <Component
                {...props}
                animate={{
                    background: store.background,
                }}
                onClick={() => {
                    setStore({ background: randomColor() })
                }}
            >
                {/* Renderizar el formulario dentro del componente decorado */}
                <form>
                    <input
                        type="text"
                        value={inputValue}
                        onChange={handleChange}
                        placeholder="Introduce texto"
                    />
                    <button type="button" onClick={handleReset}>
                        Resetear Input
                    </button>
                </form>
            </Component>
        )
    }
}
